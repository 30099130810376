import React, { useState } from "react";
import { Button } from "reactstrap";
import { coreUpdateOrder } from "../../../../api/core/orders";
import { TOrderButtonState } from "../../../../Constant/orders";

const OrderUpdateButton = ({ order, setOrder }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <Button
          color={TOrderButtonState.colorButton[order.state]}
          className="w-100"
        >
          Загрузка...
        </Button>
      ) : (
        <Button
          onClick={() => {
            setLoading(true);
            coreUpdateOrder(order.id, {
              state: TOrderButtonState.nextState[order.state],
            }).then((response) => {
              if (response.status === 200) {
                setOrder({ ...order, state: response.data.state });
                setLoading(false);
              }
            });
          }}
          color={TOrderButtonState.colorButton[order.state]}
          className="w-100"
        >
          {TOrderButtonState.textButton[order.state]}
        </Button>
      )}
    </>
  );
};

export default OrderUpdateButton;
