import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Col, Container, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import {
  coreGetDetailRestaurantSettings,
  coreUpdateRestaurantSettings,
} from "../../../../api/core/restaurants";
import Loader from "../../../../Layout/Loader";
import { useNavigate } from "react-router";
import PaymentMethods from "./PaymentMethods";
import DeliveryTypes from "./DeliveryTypes";
import WorkTime from "./WorkTime";
import SweetAlert from "sweetalert2";
import { useUserSelector } from "../../../../store/useSelectors";
import TelegramBot from "./TelegramBot";
import NotificationSettingsCard from "./NotificationSettingsCard";
import OrderByTime from "./OrderByTime";

const RestaurantSettingsPage = () => {
  const [loading, setLoading] = useState(false);
  const {
    setValue,
    formState: { errors },
  } = useForm();
  const [settings, setSettings] = useState(null);
  const [workTime, setWorkTime] = useState(null);
  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const onSubmit = (data) => {
    const payload = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== settings[key]) {
        payload[key] = data[key];
      }
    });

    if (Object.keys(payload).length === 0) return;

    setLoading(true);
    coreUpdateRestaurantSettings(payload)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Настройки успешно обновлены!", "", "success");
          setSettings(response.data);
          navigate(
            `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/settings`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    coreGetDetailRestaurantSettings().then((response) => {
      if (response.status === 200) {
        setSettings(response.data);
        setWorkTime(JSON.parse(JSON.stringify(response.data.work_time)));
      }
    });
  }, []);

  useEffect(() => {
    if (!settings) return;
    Object.keys(settings).forEach((key) => {
      setValue(key, settings[key]);
    });
  }, [settings]);

  if (!settings || !workTime) return <Loader />;

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Настройки заведения"
        title="Общие"
        parent="Настройки"
      />
      <Container fluid={true}>
        <Row>
          <Col xs={12} lg={6}>
            <PaymentMethods
              loading={loading}
              onSubmit={onSubmit}
              settings={settings}
            />
          </Col>
          <Col xs={12} lg={6}>
            <DeliveryTypes
              loading={loading}
              onSubmit={onSubmit}
              settings={settings}
            />
          </Col>
          <Col xs={12}>
            <OrderByTime
              loading={loading}
              onSubmit={onSubmit}
              settings={settings}
            />
          </Col>
          <Col xs={12}>
            <WorkTime
              loading={loading}
              setWorkTime={setWorkTime}
              workTime={workTime}
              onSubmit={onSubmit}
            />
          </Col>
          <Col xs={12}>
            <NotificationSettingsCard
              loading={loading}
              onSubmit={onSubmit}
              settings={settings}
            />
          </Col>
          <Col xs={12} lg={12}>
            <TelegramBot loading={loading} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RestaurantSettingsPage;
