// //Samp lePage
import OrdersManagerPage from "../Components/Pages/Orders/OrdersManagerPage";
import OrdersListPage from "../Components/Pages/Orders/OrdersListPage";
import Restaurants from "../Components/Pages/Restaurants";
import CreateRestaurantPage from "../Components/Pages/Restaurants/CreateRestaurantPage";
import ProductsPage from "../Components/Pages/Menu/Products";
import NewProductPage from "../Components/Pages/Menu/NewProduct";
import NewCategoryPage from "../Components/Pages/Menu/Categories/NewCategoryPage";
import CategoriesListPage from "../Components/Pages/Menu/Categories";
import UpdateCategoryPage from "../Components/Pages/Menu/Categories/UpdateCategoryPage";
import UpdateProductPage from "../Components/Pages/Menu/UpdateProduct";
import RestaurantSettingsPage from "../Components/Pages/Settings/General";
import OrderDetailPage from "../Components/Pages/Orders/OrderDetailPage";
import DashboardPage from "../Components/Pages/Dashboard";
import UsersListPage from "../Components/Pages/Users/UsersListPage";
import UserDetailPage from "../Components/Pages/Users/UsersDetailPage";
import DeliverySettings from "../Components/Pages/Settings/Delivery";
import CreateMailingPage from "../Components/Pages/Mailings/CreateMailing";
import MailingListPage from "../Components/Pages/Mailings/MailingList";
import TariffPage from "../Components/Pages/Tariffs";
import Promotion from "../Components/Pages/Settings/Promotion";
import OptionGroupListPage from "../Components/Pages/Menu/OptionGroups/OptionGroupListPage";
import CreateOptionsGroupPage from "../Components/Pages/Menu/OptionGroups/CreateOptionsGroupPage";
import UpdateOptionGroupPage from "../Components/Pages/Menu/OptionGroups/UpdateOptionGroupPage";
import Transactions from "../Components/Pages/Transactions/Transactions";
import MailingDetailPage from "../Components/Pages/Mailings/MailingDetailPage/MailingDetailPage";
import BonusesSettings from "../Components/Pages/Settings/BonusesSettings/BonusesSettings";
import RestaurantStaffListPage from "../Components/Pages/Settings/RestaurantStaffListPage/RestaurantStaffListPage";
import OnlinePaymentsSettings from "../Components/Pages/Settings/OnlinePayments/OnlinePayments";
import DesignSettings from "../Components/Pages/Settings/DesignSettings/DesignSettings";
import RestaurantPromocodeListPage from "../Components/Pages/Settings/PromocodeSettings/RestaurantPromocodeListPage";
import CreateRestaurantPromocodePage from "../Components/Pages/Settings/PromocodeSettings/CreateRestaurantPromocodePage";
import RestaurantPromocodeDetailPage from "../Components/Pages/Settings/PromocodeSettings/RestaurantPromocodeDetailPage";
import RestaurantSpotListPage from "../Components/Pages/Settings/RestaurantSpots/RestaurantSpotListPage";
import CreateSpots from "../Components/Pages/Settings/RestaurantSpots/CreateRestaurantSpotPage";
import SpotsDetailPage from "../Components/Pages/Settings/RestaurantSpots/UpdateRestaurantSpotPage";

const restaurantRoutes = [
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/dashboard/`,
    Component: <DashboardPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/orders-manager/`,
    Component: <OrdersManagerPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/orders/`,
    Component: <OrdersListPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/orders/:orderId`,
    Component: <OrderDetailPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/options/`,
    Component: <OptionGroupListPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/options/:optionsId`,
    Component: <UpdateOptionGroupPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/options/new/`,
    Component: <CreateOptionsGroupPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/products/`,
    Component: <ProductsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/products/new/`,
    Component: <NewProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/products/:productId`,
    Component: <UpdateProductPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/categories/`,
    Component: <CategoriesListPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/categories/new/`,
    Component: <NewCategoryPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/categories/:categoryId`,
    Component: <UpdateCategoryPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings`,
    Component: <RestaurantSettingsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/delivery`,
    Component: <DeliverySettings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/bonuses`,
    Component: <BonusesSettings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/payments`,
    Component: <OnlinePaymentsSettings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/design`,
    Component: <DesignSettings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/promotion`,
    Component: <Promotion />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/promocodes`,
    Component: <RestaurantPromocodeListPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/spots`,
    Component: <RestaurantSpotListPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/spots/:spotsId`,
    Component: <SpotsDetailPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/spots/new`,
    Component: <CreateSpots />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/promocodes/:promocodeId`,
    Component: <RestaurantPromocodeDetailPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/promocodes/new`,
    Component: <CreateRestaurantPromocodePage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/settings/staff`,
    Component: <RestaurantStaffListPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/users`,
    Component: <UsersListPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/users/:restaurantUserId`,
    Component: <UserDetailPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/mailings`,
    Component: <MailingListPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/mailings/:mailingsId`,
    Component: <MailingDetailPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/mailings/new`,
    Component: <CreateMailingPage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/restaurants/:restaurantId/tariffs`,
    Component: <TariffPage />,
  },
];
export const routes = [
  // //page
  { path: `${process.env.PUBLIC_URL}/restaurants`, Component: <Restaurants /> },
  {
    path: `${process.env.PUBLIC_URL}/restaurants/create`,
    Component: <CreateRestaurantPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/transactions/`,
    Component: <Transactions />,
  },
  ...restaurantRoutes,
];
