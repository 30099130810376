import { coreGetRestaurantSpots } from "../../../api/core/restaurants";
import { AsyncPaginate } from "react-select-async-paginate";
import RestrictedElement from "../Card/RestrictedElement";
import { useUserSelector } from "../../../store/useSelectors";
import { TTariffOption } from "../../../Constant/tariffs";

const RestaurantSpotSelect = ({
  defaultValue,
  onChange,
  isActiveOnly,
  isDisabled,
  ...props
}) => {
  const { selectedRestaurant } = useUserSelector();
  const isRestricted = !selectedRestaurant?.tariff.options[TTariffOption.SPOTS];

  const loadOptions = async (search, loadedOptions, { page }) => {
    const filters = {};
    if (isActiveOnly) {
      filters.is_active = true;
    }

    const response = await coreGetRestaurantSpots(page, filters, 40);
    return {
      options: response.data.items,
      hasMore: response.data.page < response.data.pages,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <RestrictedElement isRestricted={isRestricted}>
      <AsyncPaginate
        {...props}
        isDisabled={isDisabled || isRestricted}
        loadOptions={loadOptions}
        additional={{
          page: 1,
        }}
        isSearchable={false}
        defaultValue={defaultValue}
        placeholder={"Выберите точку продаж"}
        loadingMessage={() => "Загрузка..."}
        onChange={onChange}
        getOptionLabel={(value) => value.title}
        getOptionValue={(value) => value.id}
      />
    </RestrictedElement>
  );
};

export default RestaurantSpotSelect;
