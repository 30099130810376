import React, { Fragment, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import HeaderCard from "../.././Common/Component/HeaderCard";
import { Controller, useForm } from "react-hook-form";
import { coreCreateRestaurant } from "../../../api/core/restaurants";
import { useNavigate } from "react-router";
import SweetAlert from "sweetalert2";
import { DOCS_URLS } from "../../../Constant/docs";
import ButtonLoading from "../../Common/ButtonLoading/ButtonLoading";

import AddressSelect from "../../Common/AddressSelect/AddressSelect";

const CreateRestaurantPage = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data, e) => {
    data.spot = {
      title: data.title,
      address: data.address.text,
    };
    delete data.address;

    setLoading(true);
    coreCreateRestaurant(data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          SweetAlert.fire("Заведение успешно добавлено!", "", "success");
          navigate(`${process.env.PUBLIC_URL}/restaurants/`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Заведения"
        mainTitle="Добавление заведения"
        title="Добавление заведения"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <HeaderCard title={"Данные заведения"} />
                <CardBody>
                  <FormGroup>
                    <label className="col-form-label pt-0">Название</label>
                    <input
                      type="text"
                      placeholder="Пиццерия «Альмаматер»"
                      className={`form-control ${
                        errors.title ? "is-invalid" : ""
                      }`}
                      {...register("title", { required: true })}
                    />

                    <span style={{ color: "red" }}>
                      {errors.title && "Необходимо заполнить"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="exampleInputPassword1">
                      Токен Телеграм-бота
                    </label>
                    <input
                      className={`form-control ${
                        errors.bot_token ? "is-invalid" : ""
                      }`}
                      type="text"
                      placeholder="123456:ABC-DEF1234ghIkl-zyx57W2v1u123ew11"
                      {...register("bot_token", { required: true })}
                    />
                    <small className="form-text text-muted">
                      <a href={DOCS_URLS.BOT_CREATE} target={"_blank"}>
                        Где взять токен?
                      </a>
                    </small>
                    <br />
                    <span style={{ color: "red" }}>
                      {errors.bot_token && "Необходимо заполнить токен бота"}
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label>Адрес *</Label>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <AddressSelect onChange={onChange} value={value} />
                      )}
                      rules={{ required: true }}
                    />
                    <span style={{ color: "red" }}>
                      {errors.address && "Введите Адрес"}
                    </span>
                  </FormGroup>
                </CardBody>
                <CardFooter className="text-end">
                  <ButtonLoading loading={loading}>Добавить</ButtonLoading>
                </CardFooter>
              </form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreateRestaurantPage;
