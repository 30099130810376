import React, { Fragment, useEffect, useState } from "react";
import { H6, LI, P, UL } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
} from "reactstrap";
import Board, { moveCard } from "@asseinfo/react-kanban";
import { coreGetOrders, coreUpdateOrder } from "../../../api/core/orders";
import { Link } from "react-router-dom";
import {
  TOrderButtonState,
  orderDeliveryTypeMap,
  orderPaymentMethodMap,
  orderStateToTextMap,
  stateToBadge,
  TOrderPaymentMethod,
} from "../../../Constant/orders";
import { ArrowRight } from "react-feather";
import Breadcrumbs from "../../Common/Breadcrumbs";
import { convertCoreApiDateToLocalString } from "../../../Services/utils";
import { useUserSelector } from "../../../store/useSelectors";
import UserAvatar from "../../Common/UserAvatar/UserAvatar";
import RestaurantSpotSelect from "../../Common/RestaurantSpotSelect/RestaurantSpotSelect";

const defaultBoardData = {
  columns: [
    {
      id: "CREATED",
      title: "Created",
      cards: [],
    },
    {
      id: "COOKING",
      title: "Cooking",
      cards: [],
    },
    {
      id: "DELIVERING",
      title: "Delivering",
      cards: [],
    },
  ],
};

const OrdersManagerKanbanBoard = () => {
  const [createdOrders, setCreatedOrders] = useState({});
  const [cookingOrders, setCookingOrders] = useState({});
  const [deliveringOrders, setDeliveringOrders] = useState({});
  const [loading, setLoading] = useState(false);

  const [board, setBoard] = useState(defaultBoardData);

  const { selectedRestaurant } = useUserSelector();

  const [orderFilters, setOrderFilters] = useState({});

  useEffect(() => {
    updateColumns();

    const timeout = setTimeout(() => updateColumn("CREATED"), 1000 * 10);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    updateColumns();
  }, [orderFilters]);

  useEffect(() => {
    setBoard({
      columns: [
        {
          id: "CREATED",
          title: orderStateToTextMap["CREATED"],
          cards: createdOrders,
        },
        {
          id: "COOKING",
          title: orderStateToTextMap["COOKING"],
          cards: cookingOrders,
        },
        {
          id: "DELIVERING",
          title: orderStateToTextMap["DELIVERING"],
          cards: deliveringOrders,
        },
      ],
    });
  }, [createdOrders, cookingOrders, deliveringOrders]);

  const handleCardMove = (order, source, destination) => {
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);
    coreUpdateOrder(order.id, { state: destination.toColumnId }).then(
      (response) => {
        if (response.status === 200) {
          updateColumn(source.fromColumnId);
          updateColumn(destination.toColumnId);
        }
      }
    );
  };

  const updateColumns = () => {
    setLoading(true);
    Promise.all([
      updateColumn("CREATED"),
      updateColumn("COOKING"),
      updateColumn("DELIVERING"),
    ]).then(() => {
      setLoading(false);
    });
  };

  const updateColumn = (state) => {
    if (state === "CREATED") {
      return coreGetOrders(1, { state: "CREATED", ...orderFilters }).then(
        (response) =>
          response.status === 200 && setCreatedOrders(response.data.items)
      );
    } else if (state === "COOKING") {
      return coreGetOrders(1, { state: "COOKING", ...orderFilters }).then(
        (response) =>
          response.status === 200 && setCookingOrders(response.data.items)
      );
    } else if (state === "DELIVERING") {
      return coreGetOrders(1, { state: "DELIVERING", ...orderFilters }).then(
        (response) =>
          response.status === 200 && setDeliveringOrders(response.data.items)
      );
    }
  };

  const getOrderItemsCount = (order) => {
    return order.items.reduce((prev, current) => {
      return current.quantity + prev;
    }, 0);
  };

  const formattedOrderByTime = (value) => {
    const today = new Date();
    const valueDate = new Date(value);

    if (today.getDate() === valueDate.getDate()) {
      return convertCoreApiDateToLocalString(value, {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      return convertCoreApiDateToLocalString(value, {
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  };

  return (
    <Fragment>
      {board && (
        <Card>
          <CardHeader>
            <Col>
              <h6>Точка продаж</h6>
              <RestaurantSpotSelect
                isActiveOnly
                isClearable
                isDisabled={loading}
                onChange={(restaurantSpot) => {
                  if (restaurantSpot) {
                    setOrderFilters({
                      ...orderFilters,
                      restaurant_spot_id: restaurantSpot.id,
                    });
                  } else {
                    const _orderFilters = { ...orderFilters };
                    delete _orderFilters.restaurant_spot_id;
                    setOrderFilters(_orderFilters);
                  }
                }}
              />
            </Col>
          </CardHeader>
          <CardBody className="kanban-block">
            <div className="kanban-container custom-board">
              <main className="kanban-drag">
                <Board
                  disableCardDrag={loading}
                  onCardDragEnd={handleCardMove}
                  allowAddCard={{ on: "top" }}
                  renderCard={(order, idx) => (
                    <div className="kanban-item" key={order.id}>
                      <div className={"kanban-box"}>
                        <Link
                          to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/orders/${order.id}`}
                        >
                          <span className="date">
                            {convertCoreApiDateToLocalString(order.created_at)}
                          </span>
                          <span
                            className={`badge ${
                              stateToBadge[order.state]
                            } f-right`}
                          >
                            {orderStateToTextMap[order.state]}
                          </span>
                          <H6>Заказ #{order.id}</H6>
                          <Media className="d-flex gap-2 align-items-center">
                            <UserAvatar
                              src={order.restaurant_user.user.photo}
                              id={order.restaurant_user.user.id}
                              size={30}
                            />
                            <Media body>
                              <P>{order.restaurant_user.user.first_name}</P>
                            </Media>
                          </Media>
                          <div className="d-flex mt-2 mb-2">
                            {order.cost} руб.
                          </div>
                          <div className="mt-1 text-muted">
                            <ArrowRight size={12} />{" "}
                            {orderDeliveryTypeMap[order.delivery_type]}
                          </div>

                          <div className="mt-1 text-muted">
                            <ArrowRight size={12} />{" "}
                            {orderPaymentMethodMap[order.payment_method]}
                            {order.payment_method ===
                            TOrderPaymentMethod.ONLINE ? (
                              order.payed_at ? (
                                <span className={"text-success"}>
                                  {" "}
                                  (Оплачен)
                                </span>
                              ) : (
                                <span className={"text-danger"}>
                                  {" "}
                                  (Не оплачен)
                                </span>
                              )
                            ) : null}
                          </div>
                          {order.by_time ? (
                            <div className="mt-1 warning">
                              <ArrowRight size={12} />
                              ⚠️ Ко времени:{" "}
                              {formattedOrderByTime(order.by_time)}
                            </div>
                          ) : null}
                          <div className="d-flex mt-1">
                            <UL
                              attrUL={{
                                className: "simple-list list flex-row ",
                              }}
                            >
                              <LI attrLI={{ className: " border-0" }}>
                                <i className="fa fa-paperclip"></i>
                                {getOrderItemsCount(order) + " шт"}
                              </LI>
                            </UL>
                          </div>
                        </Link>

                        <div className="mt-3">
                          <Row>
                            <Col xs={12}>
                              {loading ? (
                                <Button
                                  color={
                                    TOrderButtonState.colorButton[order.state]
                                  }
                                  className="w-100"
                                >
                                  Загрузка...
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setLoading(true);
                                    coreUpdateOrder(order.id, {
                                      state:
                                        TOrderButtonState.nextState[
                                          order.state
                                        ],
                                    }).then(
                                      (response) =>
                                        response.status === 200 &&
                                        updateColumns()
                                    );
                                  }}
                                  color={
                                    TOrderButtonState.colorButton[order.state]
                                  }
                                  className="w-100"
                                >
                                  {TOrderButtonState.textButton[order.state]}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )}
                >
                  {board}
                </Board>
              </main>
            </div>
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};
const OrdersManagerPage = () => {
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle="Менеджер заказов"
        parent="Заказы"
        title="Менеджер заказов"
      />
      <Container fluid={true} className="jkanban-container">
        <Row>
          <Col xs="12">
            <OrdersManagerKanbanBoard />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default OrdersManagerPage;
