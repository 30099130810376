import { Card, CardBody, Col, Row } from "reactstrap";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const CreateRestaurantCard = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/restaurants/create`}>
      <Card className="add-card h-100">
        <CardBody className={"add-card-restaurant-body"}>
          <Row className={"text-center"}>
            <Col xs={12}>
              <FaPlus size={32} className={"bg-primary b-r-7"} />
            </Col>
            <Col xs={12} className={"mt-1"}>
              {`Добавить заведение`}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Link>
  );
};

export default CreateRestaurantCard;
