import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../Layout/Loader";
import { FaPlus } from "react-icons/fa";
import { useUserSelector } from "../../../../store/useSelectors";
import { coreGetOptionGroups } from "../../../../api/core/options";
import { DOCS_URLS } from "../../../../Constant/docs";
import CommonModal from "../../../Common/CommonModal/CommonModal";

const OptionGroupListPage = () => {
  const { selectedRestaurant } = useUserSelector();
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = () => {
    setOptions(null);
    coreGetOptionGroups().then((response) => {
      if (response.status === 200) {
        setOptions(response.data);
      }
    });
  };

  if (!options) return <Loader />;

  return (
    <Fragment>
      <Breadcrumbs
        parent="Меню"
        title="Модификаторы"
        mainTitle="Модификаторы"
        docsLink={DOCS_URLS.MENU_MODIFICATORS}
      />
      <Container fluid={true} className="product-wrapper" id="product-wrapper">
        <Col xs={12} className={"mb-3"}>
          <Link
            to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/options/new`}
          >
            <Card onClick={() => toggle()} className="add-card h-100">
              <CardBody className={""}>
                <Row className={"text-center"}>
                  <Col xs={12}>
                    <FaPlus size={32} className={"bg-primary b-r-7"} />
                  </Col>
                  <Col xs={12} className={"mt-1"}>
                    Добавить набор модификаторов
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Link>
          {options &&
            options.map((item, index) => (
              <div key={item.id} style={{ cursor: "pointer" }}>
                <div
                  onClick={() =>
                    navigate(
                      `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/options/${item.id}`
                    )
                  }
                  className="category-container card"
                >
                  <div className={"card-body"}>
                    <div className="d-flex aligh-center gap-2">
                      <span>{item.title}</span>
                      <span style={{ opacity: 0.5 }}>
                        {item.title_internal}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Col>
      </Container>
      <CommonModal title="Создание группы" isOpen={modal} toggler={toggle}>
        ....
      </CommonModal>
    </Fragment>
  );
};

export default OptionGroupListPage;
