import { Breadcrumbs } from "../../../../AbstractElements";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Loader from "../../../../Layout/Loader";
import tableColumns from "./table";
import { FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useUserSelector } from "../../../../store/useSelectors";
import DataTable from "react-data-table-component";
import { coreGetRestaurantPromocodes } from "../../../../api/core/promocodes";
import RestrictedElement from "../../../Common/Card/RestrictedElement";
import { TTariffOption } from "../../../../Constant/tariffs";

const RestaurantPromocodeListPage = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();
  const { selectedRestaurant } = useUserSelector();

  const fetchData = (page, newPerPage) => {
    setLoading(true);
    coreGetRestaurantPromocodes(page, {}, newPerPage || perPage).then(
      (response) => {
        setData(response?.data.items);
        setTotalRows(response?.data.total);
        setLoading(false);
      }
    );
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    fetchData(page, newPerPage);
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <>
      <Breadcrumbs mainTitle="Промокоды" title="Промокоды" parent="Настройки" />
      <Container fluid={true}>
        <RestrictedElement
          isRestricted={
            !selectedRestaurant.tariff.options[TTariffOption.PROMOCODES]
          }
        >
          <Col xs={12} className={"mb-5"}>
            <Link
              to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/settings/promocodes/new`}
            >
              <Card className="add-card h-100">
                <CardBody className={""}>
                  <Row className={"text-center"}>
                    <Col xs={12}>
                      <FaPlus size={32} className={"bg-primary b-r-7"} />
                    </Col>
                    <Col xs={12} className={"mt-1"}>
                      Создать промокод
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <DataTable
            data={data}
            columns={tableColumns}
            striped={true}
            center={true}
            progressPending={loading}
            progressComponent={<Loader />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onRowClicked={(row) =>
              navigate(
                `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/settings/promocodes/${row.id}`
              )
            }
            highlightOnHover
            pointerOnHover
          />
        </RestrictedElement>
      </Container>
    </>
  );
};

export default RestaurantPromocodeListPage;
