import React, { useEffect } from "react";
import L from "leaflet";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { MoneyFormat } from "../../../../Services/utils";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import {
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import RestaurantSpotSelect from "../../../Common/RestaurantSpotSelect/RestaurantSpotSelect";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";
import SweetAlert from "sweetalert2";
import { v4 as uuidv4 } from "uuid";

const EditControlFC = ({
  geojson,
  setGeojson,
  toggleCreateDeliveryAreaModal,
  createDeliveryAreaModal,
}) => {
  const ref = React.useRef(null);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    rerenderLayers();
  }, [geojson]);

  const onCreateDeliveryArea = (data) => {
    const geo = ref.current?.toGeoJSON();
    geo.features.forEach((f) => {
      if (Object.keys(f.properties).length === 0) {
        data.temporaryId = uuidv4();
        f.properties = data;
      }
    });
    setGeojson(geo);
    toggleCreateDeliveryAreaModal();
    reset();
    SweetAlert.fire(
      "Для того, чтобы сохранить изменения — нажмите зелёную кнопку «Сохранить»",
      "",
      "info"
    );
  };

  const rerenderLayers = () => {
    ref.current?.clearLayers();
    if (geojson) {
      L.geoJSON(geojson).eachLayer((layer) => {
        layer.bindTooltip(
          L.tooltip().setContent(
            `
                            <b>${layer.feature.properties.name}</b>
                            <br />
                            Стоимость доставки: ${MoneyFormat.format(
                              layer.feature.properties.cost
                            )}
                            <br />
                            Минимальная стоимость заказа: ${MoneyFormat.format(
                              layer.feature.properties.minimal_cost
                            )}
                            <br />
                Точка продаж: ${
                  layer.feature.properties?.restaurant_spot?.title
                }
                    </div>
                    `
          )
        );
        ref.current?.addLayer(layer);
      });
    }
  };

  const handleChange = (e) => {
    const geo = ref.current?.toGeoJSON();
    if (e.type === "draw:created") {
      toggleCreateDeliveryAreaModal();
    } else {
      setGeojson(geo);
      SweetAlert.fire(
        "Для того, чтобы сохранить изменения — нажмите зелёную кнопку «Сохранить»",
        "",
        "info"
      );
    }
  };

  return (
    <>
      {" "}
      <FeatureGroup ref={ref}>
        <EditControl
          position="topright"
          onEdited={handleChange}
          onCreated={handleChange}
          onDeleted={handleChange}
          draw={{
            rectangle: false,
            circle: false,
            polyline: false,
            polygon: true,
            marker: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
      <CommonModal
        title="Введите данные выделенного района"
        isOpen={createDeliveryAreaModal}
        toggler={toggleCreateDeliveryAreaModal}
      >
        <Form
          className="theme-form"
          onSubmit={handleSubmit(onCreateDeliveryArea)}
        >
          <CardBody>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>Название</Label>
                  <input
                    type="text"
                    name="name"
                    placeholder=""
                    className={`form-control digits ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("name", { required: true })}
                  />
                  <span style={{ color: "red" }}>
                    {errors.name && "Введите название"}
                  </span>
                </FormGroup>
                <FormGroup>
                  <Label>Минимальная стоимость заказа</Label>
                  <input
                    type="number"
                    name="minimal_cost"
                    placeholder=""
                    className={`form-control digits ${
                      errors.minimal_cost ? "is-invalid" : ""
                    }`}
                    {...register("minimal_cost", { required: true })}
                  />
                  <span style={{ color: "red" }}>
                    {errors.minimal_cost &&
                      "Введите минимальную стоимость доставки"}
                  </span>
                </FormGroup>
                <FormGroup>
                  <Label>Стоимость доставки</Label>
                  <input
                    type="number"
                    name="cost"
                    placeholder=""
                    className={`form-control digits ${
                      errors.cost ? "is-invalid" : ""
                    }`}
                    {...register("cost", { required: true })}
                  />
                  <span style={{ color: "red" }}>
                    {errors.cost && "Введите стоимость доставки"}
                  </span>
                </FormGroup>
                <FormGroup>
                  <Label>Точка продаж *</Label>
                  <Controller
                    name="restaurant_spot"
                    control={control}
                    {...register("restaurant_spot", { required: true })}
                    render={({ field: { onChange, value } }) => (
                      <RestaurantSpotSelect
                        onChange={onChange}
                        defaultValue={value}
                        isActiveOnly
                      />
                    )}
                  />
                  <span style={{ color: "red" }}>
                    {errors.restaurant_spot && "Выберите точку продаж"}
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div className="text-end">
              <ButtonLoading loading={false}>Сохранить</ButtonLoading>
            </div>
          </CardFooter>
        </Form>
      </CommonModal>
    </>
  );
};

export default EditControlFC;
