import React, { useEffect } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  Label,
  Media,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";
import RestrictedElement from "../../../Common/Card/RestrictedElement";
import { TTariffOption } from "../../../../Constant/tariffs";

const OrderByTime = ({ onSubmit, settings, loading }) => {
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (!settings) return;
    setValue("order_by_time", settings.order_by_time);
  }, [settings]);

  return (
    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardBody>
          <Row>
            <Col xs={12}>
              <RestrictedElement optionName={TTariffOption.ORDER_BY_TIME}>
                <Media>
                  <Label className="col-form-label m-r-10">
                    Заказ ко времени
                  </Label>
                  <Media body className="text-end switch-lg icon-state">
                    <Label className="switch">
                      <input
                        type="checkbox"
                        {...register("order_by_time", {
                          value: settings.order_by_time,
                        })}
                      />
                      <span className="switch-state" />
                    </Label>
                  </Media>
                </Media>
              </RestrictedElement>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="text-end">
            <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
          </div>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default OrderByTime;
