export const TTariffOption = {
  ADVANCED_STATS: "ADVANCED_STATS",
  PRODUCT_OPTIONS: "PRODUCT_OPTIONS",
  DELIVERY: "DELIVERY",
  BASE_LOADER: "BASE_LOADER",
  PRIORITY_PHOTO_LOADING: "PRIORITY_PHOTO_LOADING",
  MAILINGS_TELEGRAM_TYPE: "MAILINGS_TELEGRAM_TYPE",
  BONUSES: "BONUSES",
  ONLINE_PAYMENTS: "ONLINE_PAYMENTS",
  DESIGN: "DESIGN",
  PROMOCODES: "PROMOCODES",
  SPOTS: "SPOTS",
  ORDER_BY_TIME: "ORDER_BY_TIME",
};

export const TTariffName = {
  FREE: "FREE",
  START: "START",
  PRO: "PRO",
};

export const TTariffPeriod = {
  MONTH: "MONTH",
  YEAR: "YEAR",

  getShortTitle: (period) => {
    return {
      [TTariffPeriod.MONTH]: "мес",
      [TTariffPeriod.YEAR]: "год",
    }[period];
  },
};
