import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import { Breadcrumbs, H6 } from "../../../../AbstractElements";
import P from "../../../Common/Paragraph";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";
import {
  restaurantPromocodeTypeToTextMap,
  TRestaurantPromocodeType,
} from "../../../../Constant/promocodes";
import { coreRestaurantPromocodeCreate } from "../../../../api/core/promocodes";
import Select from "react-select";
import { coreGetProducts } from "../../../../api/core/products";
import RestrictedElement from "../../../Common/Card/RestrictedElement";
import { TTariffOption } from "../../../../Constant/tariffs";
import RestrictedCard from "../../../Common/Card/RestrictedCard";

const CreateRestaurantPromocodePage = () => {
  const {
    register,
    watch,
    handleSubmit,
    getValues,
    control,

    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [selectLoader, setSelectLoader] = useState("Загрузка...");

  const { selectedRestaurant } = useUserSelector();
  const [product, setProduct] = useState([]);

  const fetchProducts = async () => {
    coreGetProducts().then((res) => {
      const currentRes = res.data.map((i) => ({
        value: i.id,
        label: i.title,
      }));
      setProduct(currentRes);
      if (currentRes.length) {
        setSelectLoader("Нет товаров для выбора");
      }
    });
  };

  const onSubmit = (data) => {
    if (data.valid_from) {
      data.valid_from = new Date(data.valid_from)
        .toISOString()
        .replace("Z", "");
    } else {
      delete data.valid_from;
    }

    if (data.valid_from) {
      data.valid_to = new Date(data.valid_to).toISOString().replace("Z", "");
    } else {
      delete data.valid_to;
    }

    if (data.type === TRestaurantPromocodeType.MONEY) {
      delete data.product_id;
    } else {
      delete data.money_value;
    }

    setLoading(true);

    coreRestaurantPromocodeCreate(data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          SweetAlert.fire("Промокод создан!", "", "success");
          navigate(
            `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/settings/promocodes`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectClick = () => {
    if (!clicked) {
      fetchProducts();
      setClicked(true);
    }
  };

  useEffect(() => {}, [watch()]);

  return (
    <>
      <Breadcrumbs
        parent="Промокоды"
        title="Создание промокода"
        mainTitle="Создание промокода"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <RestrictedCard
              isRestricted={
                !selectedRestaurant.tariff.options[TTariffOption.PROMOCODES]
              }
            >
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <Card>
                        <Media className="p-20">
                          <div className="radio radio-primary me-3">
                            <input
                              id="radio14"
                              type="radio"
                              name="type"
                              value={TRestaurantPromocodeType.MONEY}
                              defaultChecked={true}
                              {...register("type", { required: true })}
                            />
                            <Label for="radio14"></Label>
                          </div>
                          <Media body>
                            <H6 attrH6={{ className: "mt-0 mega-title-badge" }}>
                              {
                                restaurantPromocodeTypeToTextMap[
                                  TRestaurantPromocodeType.MONEY
                                ]
                              }
                            </H6>
                            <P>
                              {
                                "При применении вычитает указанную сумму из стоимости заказа"
                              }
                            </P>
                          </Media>
                        </Media>
                      </Card>
                    </Col>
                    <Col sm="6">
                      <Card>
                        <Media className="p-20">
                          <div className="radio radio-secondary me-3">
                            <input
                              id="radio13"
                              type="radio"
                              name="type"
                              value={TRestaurantPromocodeType.FREE_PRODUCT}
                              {...register("type", { required: true })}
                            />
                            <Label for="radio13"></Label>
                          </div>
                          <Media body>
                            <H6 className="mt-0 mega-title-badge">
                              {
                                restaurantPromocodeTypeToTextMap[
                                  TRestaurantPromocodeType.FREE_PRODUCT
                                ]
                              }
                            </H6>
                            <P>
                              {
                                "При применении добавляет указанный товар в корзину с нулевой стоимостью"
                              }
                            </P>
                          </Media>
                        </Media>
                      </Card>
                    </Col>
                  </Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Промокод *</Label>
                      <input
                        className={`form-control ${
                          errors.code ? "is-invalid" : ""
                        }`}
                        name="code"
                        type={"text"}
                        rows={9}
                        {...register("code", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.code && "Введите  Промокод"}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    {getValues("type") ===
                    TRestaurantPromocodeType.FREE_PRODUCT ? (
                      <FormGroup>
                        <Label>Товар, который будет в подарок *</Label>
                        <Controller
                          name="product_id"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              className="basic-multi-select col-sm-12"
                              options={product}
                              value={product.find(
                                (option) => option.value === value
                              )}
                              placeholder="Выберите"
                              noOptionsMessage={() => selectLoader}
                              onMenuOpen={() => {
                                handleSelectClick();
                              }}
                              onChange={(val) => onChange(val.value)}
                            />
                          )}
                          rules={{ required: true }}
                        />
                        <span style={{ color: "red" }}>
                          {errors.category_id && "Необходимо выбрать товар"}
                        </span>
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Label>Размер скидки *</Label>
                        <input
                          className={`form-control ${
                            errors.money_value ? "is-invalid" : ""
                          }`}
                          name="money_value"
                          type={"number"}
                          rows={9}
                          {...register("money_value", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.money_value && "Введите размер скидки"}
                        </span>
                      </FormGroup>
                    )}
                  </Col>
                  <Row className="flex-column flex-md-row">
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>Дата начала действия</Label>
                        <input
                          className={`form-control ${
                            errors.valid_from ? "is-invalid" : ""
                          }`}
                          type="datetime-local"
                          name="valid_from"
                          {...register("valid_from")}
                        />
                        <span style={{ color: "red" }}>
                          {errors.valid_from && "Неверная дата"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>Дата окончания действия</Label>
                        <input
                          className={`form-control ${
                            errors.valid_to ? "is-invalid" : ""
                          }`}
                          type="datetime-local"
                          name="valid_to"
                          {...register("valid_to")}
                        />
                        <span style={{ color: "red" }}>
                          {errors.valid_to && "Неверная дата"}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={loading}>Создать</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </RestrictedCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateRestaurantPromocodePage;
