import React from "react";
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { convertCoreApiDateToInputDate } from "../../../../Services/utils";
import RestrictedElement from "../../../Common/Card/RestrictedElement";
import { useUserSelector } from "../../../../store/useSelectors";
import { TTariffOption } from "../../../../Constant/tariffs";

const OrderAdditionalInfoCard = ({ order }) => {
  const { selectedRestaurant } = useUserSelector();

  return (
    <Card>
      <HeaderCard title={`Дополнительная информация`} />
      <CardBody>
        <Form>
          <Row>
            <Col xs={12} xl={6}>
              <FormGroup>
                <Label>
                  <h6>{`Дата создания`}</h6>
                </Label>
                <input
                  className={`form-control `}
                  type="datetime-local"
                  name="bonus_receive_max"
                  disabled
                  value={convertCoreApiDateToInputDate(order.created_at)}
                />
              </FormGroup>
            </Col>
            <Col xs={12} xl={6}>
              <FormGroup>
                <Label>
                  <h6>{`Точка продаж`}</h6>
                </Label>
                <input
                  className={`form-control `}
                  type="text"
                  name="bonuses_spent"
                  disabled
                  value={order.restaurant_spot.title}
                />
              </FormGroup>
            </Col>
          </Row>
          <RestrictedElement
            isRestricted={
              !selectedRestaurant?.tariff.options[TTariffOption.BONUSES]
            }
          >
            <Row>
              <Col xs={12} xl={6}>
                <FormGroup>
                  <Label>
                    <h6>{`Бонусов получено`}</h6>
                  </Label>
                  <input
                    className={`form-control `}
                    type="number"
                    name="bonuses_received"
                    disabled
                    value={order.bonuses_received}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} xl={6}>
                <FormGroup>
                  <Label>
                    <h6>{`Бонусов потрачено`}</h6>
                  </Label>
                  <input
                    className={`form-control `}
                    type="number"
                    name="bonuses_spent"
                    disabled
                    value={order.bonuses_spent}
                  />
                </FormGroup>
              </Col>
            </Row>
          </RestrictedElement>
        </Form>
      </CardBody>
    </Card>
  );
};

export default OrderAdditionalInfoCard;
