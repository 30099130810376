export const formatPhoneNumber = (phoneNumber) => {
  return phoneNumber
    .replace(/\D+/g, "")
    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
};

export const MoneyFormat = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const convertCoreApiDateToLocalString = (date, options) => {
  if (!date) return null;
  return new Date(date + "Z").toLocaleString(undefined, options);
};

export const convertCoreApiDateToInputDate = (date) => {
  return new Date(date + "Z").toLocaleString("sv-SE");
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const limitString = (string, maxLength) => {
  if (string.length > maxLength) {
    return string.slice(0, maxLength) + "...";
  }
  return string;
};

export const isValidJsonObject = (string) => {
  const currentString = string.trim();

  if (
    currentString[0] !== "{" ||
    currentString[currentString.length - 1] !== "}"
  )
    return false;

  try {
    JSON.parse(currentString);
  } catch (e) {
    return false;
  }

  return true;
};
