import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import Loader from "../../../../Layout/Loader";
import SweetAlert from "sweetalert2";
import { useNavigate, useParams } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

import {
  coreDeleteRestaurantSpotsDetail,
  coreGetRestaurantSpotsDetail,
  coreUpdateRestaurantSpots,
} from "../../../../api/core/restaurants";
import { convertCoreApiDateToInputDate } from "../../../../Services/utils";
import AddressSelect from "../../../Common/AddressSelect/AddressSelect";
import RestrictedCard from "../../../Common/Card/RestrictedCard";
import { TTariffOption } from "../../../../Constant/tariffs";

const UpdateRestaurantSpotPage = () => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,

    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { spotsId } = useParams();

  const { selectedRestaurant } = useUserSelector();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    coreGetRestaurantSpotsDetail(spotsId).then((response) => {
      setData(response.data);
    });
  };

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
    }
  }, [data]);

  const onSubmit = (dataForm) => {
    const currentData = {
      title: dataForm.title,
      address: dataForm.address.text,
    };

    if (currentData.title === data.title) {
      delete currentData.title;
    }
    if (currentData.address === data.address.text) {
      delete currentData.address;
    }

    if (Object.keys(currentData).length === 0) return;

    setLoading(true);

    coreUpdateRestaurantSpots(spotsId, currentData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          SweetAlert.fire("Точка продаж обновлена!", "", "success");
          setData(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteSpots = () => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "После удаления точку продаж нельзя будет восстановить",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        coreDeleteRestaurantSpotsDetail(spotsId).then((response) => {
          if (response.status === 200) {
            SweetAlert.fire("Точка продаж успешно удалена!", "", "success");
            navigate(
              `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/settings/spots`
            );
          }
        });
      }
    });
  };

  const onStopSpots = () => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "Точка продаж будет временно закрыта",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const currentDate = new Date();
        coreUpdateRestaurantSpots(spotsId, {
          hidden_at: convertCoreApiDateToInputDate(currentDate),
        })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              SweetAlert.fire("Точка продаж временно закрыта!", "", "success");
              setData(response.data);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const onActiveSpots = () => {
    coreUpdateRestaurantSpots(spotsId, { hidden_at: null })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          SweetAlert.fire("Точка продаж открыта!", "", "success");
          setData(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {}, [watch()]);

  if (!data) return <Loader />;

  return (
    <>
      <Breadcrumbs
        parent="Точки продаж"
        title="Редактирование точки продаж"
        mainTitle="Редактирование точки продаж"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <RestrictedCard
              isRestricted={
                !selectedRestaurant.tariff.options[TTariffOption.SPOTS]
              }
            >
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Название *</Label>
                      <input
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        disabled={data.deleted_at}
                        name="title"
                        type={"text"}
                        rows={9}
                        {...register("title", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.title && "Введите  Название"}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Адрес *</Label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <AddressSelect
                            isDisabled={data.deleted_at}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                        rules={{ required: true }}
                      />
                      <span style={{ color: "red" }}>
                        {errors.address && "Введите Адрес"}
                      </span>
                    </FormGroup>
                  </Col>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    {data.is_active === true ? (
                      <ButtonLoading
                        onClick={onStopSpots}
                        color="primary"
                        loading={loading}
                      >
                        Временно закрыть точку
                      </ButtonLoading>
                    ) : (
                      <ButtonLoading
                        onClick={onActiveSpots}
                        color="primary"
                        loading={loading}
                      >
                        Возобновить точку
                      </ButtonLoading>
                    )}

                    <ButtonLoading
                      onClick={deleteSpots}
                      color="danger"
                      loading={loading}
                    >
                      Навсегда закрыть точку
                    </ButtonLoading>
                    <ButtonLoading loading={loading}>Обновить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </RestrictedCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateRestaurantSpotPage;
