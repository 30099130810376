import { convertCoreApiDateToLocalString } from "../../../../Services/utils";
import { Col, Row } from "reactstrap";
import { getBlockEndDate, getBlockReason } from "../utils";
import CommonModal from "../../../Common/CommonModal/CommonModal";

const UserBlocksModal = ({ modal, toggle, row }) => {
  return (
    <CommonModal
      title="Информация о блокировке"
      isOpen={modal}
      toggler={toggle}
    >
      <Row>
        <Col xs={12}>
          <h6>{`Причина блокировки`}</h6>
          <p className="text-break">{getBlockReason(row?.reason)}</p>
        </Col>
        <hr className={"mt-4 mb-4"} />
        <Col xs={12}>
          <h6>{`Конец блокировки`}</h6>
          <p>
            {row?.end_date == null
              ? getBlockEndDate(row?.end_date)
              : convertCoreApiDateToLocalString(row?.end_date)}
          </p>
        </Col>
        <hr className={"mt-4 mb-4"} />
        <Col xs={12}>
          <h6>{`Дата блокировки`}</h6>
          <p>
            {row?.created_at
              ? convertCoreApiDateToLocalString(row?.created_at)
              : ""}
          </p>
        </Col>
        <hr className={"mt-4 mb-4"} />
        <Col xs={12}>
          <h6>{`Блокировка активна`}</h6>
          <p>{row?.is_active ? "Активна" : "Неактивна"}</p>
        </Col>
        <hr className={"mt-4 mb-4"} />
      </Row>
    </CommonModal>
  );
};

export default UserBlocksModal;
