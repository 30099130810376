import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";
import { corePostCreateRestaurantSpots } from "../../../../api/core/restaurants";
import AddressSelect from "../../../Common/AddressSelect/AddressSelect";
import RestrictedCard from "../../../Common/Card/RestrictedCard";
import { TTariffOption } from "../../../../Constant/tariffs";

const CreateRestaurantSpotPage = () => {
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { selectedRestaurant } = useUserSelector();

  const onSubmit = (data) => {
    setLoading(true);

    corePostCreateRestaurantSpots({
      title: data.title,
      address: data.address.text,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          SweetAlert.fire("Точка продаж создана!", "", "success");
          navigate(
            `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/settings/spots`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {}, [watch()]);

  return (
    <>
      <Breadcrumbs
        parent="Точки продаж"
        title="Добавить точку продаж"
        mainTitle="Добавить точку продаж"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <RestrictedCard
              isRestricted={
                !selectedRestaurant.tariff.options[TTariffOption.SPOTS]
              }
            >
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Название *</Label>
                      <input
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        name="title"
                        type={"text"}
                        rows={9}
                        {...register("title", { required: true })}
                      />
                      <span style={{ color: "red" }}>
                        {errors.title && "Введите  Название"}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup>
                      <Label>Адрес *</Label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <AddressSelect onChange={onChange} value={value} />
                        )}
                        rules={{ required: true }}
                      />
                      <span style={{ color: "red" }}>
                        {errors.address && "Введите Адрес"}
                      </span>
                    </FormGroup>
                    <Col>
                      <small className="form-text text-muted">
                        После добавления новой точки продаж увеличится плата по
                        тарифу. За каждую точку продаж +50% от цены тарифа. Если
                        у вас тариф за 3.000 руб/мес, то стоимость станет 4.500
                        руб/мес, при добавлении следующей точки: 6.000 руб/мес
                      </small>
                    </Col>
                  </Col>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={loading}>Создать</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </RestrictedCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateRestaurantSpotPage;
