import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useUserSelector } from "../../../../store/useSelectors";
import {
  coreCreateOption,
  coreDeleteGroupOptions,
  coreDeleteOption,
  coreGetOptionGroups,
  coreUpdateOption,
  coreUpdateOptionGroup,
} from "../../../../api/core/options";
import Loader from "../../../../Layout/Loader";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import SweetAlert from "sweetalert2";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";
import CommonModal from "../../../Common/CommonModal/CommonModal";

const UpdateOptionGroupPage = () => {
  const [loading, setLoading] = useState(false);
  const { selectedRestaurant } = useUserSelector();
  const [currentChangeId, setCurrentChangeId] = useState(0);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const { optionsId } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const formOption = useForm();
  const formOptionChange = useForm();

  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm },
    setValue: setValueForm,
  } = formOption;

  const {
    register: registerFormChange,
    handleSubmit: handleSubmitFormChange,
    formState: { errors: errorsFormChange },
    setValue: setValueFormChange,
  } = formOptionChange;

  const [modal, setModal] = useState(false);
  const [modalChange, setModalChange] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleChange = () => setModalChange(!modalChange);

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (!currentOptions) return;
    Object.keys(currentOptions).forEach((key) => {
      setValue(key, currentOptions[key]);
    });
  }, [currentOptions]);

  const openChangeModal = (data) => {
    toggleChange();

    Object.keys(data).forEach((key) => {
      if (key === "title" || key === "price") {
        setValueFormChange(key, data[key]);
      }
    });
    setCurrentChangeId(data.id);
  };

  const fetchOptions = () => {
    setOptions(null);
    coreGetOptionGroups().then((response) => {
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          const current = response.data[i];
          if (current.id == optionsId) {
            setCurrentOptions(current);
          }
        }
        setOptions(response.data);
      }
    });
  };

  const onSubmit = (data) => {
    const currentData = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== currentOptions[key]) {
        currentData[key] = data[key];
      }
    });

    Object.keys(currentData).forEach((key) => {
      if (currentData[key] === "") {
        currentData[key] = null;
      }
    });

    delete currentData.options;
    setLoading(true);
    coreUpdateOptionGroup(optionsId, currentData)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire(
            "Набор модификаторов успешно обновлён!",
            "",
            "success"
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addNewOption = (data) => {
    const formData = new FormData();
    formData.append("option_group_id", Number(optionsId));
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    data["option_group_id"] = optionsId;

    setLoading(true);
    coreCreateOption(data)
      .then((response) => {
        if (response.status === 200) {
          SweetAlert.fire("Добавлен новый модификатор", "", "success");
          fetchOptions();
          setModal(!modal);
        } else {
          SweetAlert.fire("Все плохо");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteGroupOptions = (productId) => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "После удаления набор модификаторов нельзя будет восстановить",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const data = {
          id: selectedRestaurant,
        };
        coreDeleteGroupOptions(data, optionsId).then((response) => {
          if (response.status === 200) {
            SweetAlert.fire(
              "Набор модификаторов успешно удален!",
              "",
              "success"
            );
            navigate(
              `${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/options`
            );
          }
        });
      }
    });
  };

  const deleteOption = (id) => {
    SweetAlert.fire({
      title: `Вы уверены?`,
      text: "После удаления модификатор нельзя будет восстановить",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Уверен",
      cancelButtonText: "Назад",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        coreDeleteOption(id).then((response) => {
          if (response.status === 200) {
            fetchOptions();
            SweetAlert.fire("Модификатор успешно удален!", "", "success");
          }
        });
      }
    });
  };

  const onChangeOption = (data) => {
    data["option_group_id"] = optionsId;

    setLoading(true);
    coreUpdateOption(currentChangeId, data)
      .then((response) => {
        if (response.status === 200) {
          toggleChange();
          fetchOptions();
          SweetAlert.fire("Модификатор успешно обновлен!", "", "success");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!options) return <Loader />;

  return (
    <>
      <Breadcrumbs
        parent="Меню"
        title="Обновление набора модификаторов"
        mainTitle="Обновление набора модификаторов"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>Название *</Label>
                        <input
                          className={`form-control ${
                            errors.title ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="title"
                          placeholder="Соусы"
                          {...register("title", { required: true })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.title &&
                            "Необходимо указать название набора модификаторов"}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>Внутреннее название</Label>
                        <input
                          className={`form-control `}
                          type="text"
                          name="title_internal"
                          placeholder="Для бургеров"
                          {...register("title_internal")}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>
                          {"Максимальное количество опций для выбора"}
                        </Label>
                        <input
                          step={1}
                          type={"number"}
                          name={"max_selected"}
                          className={`form-control digits `}
                          {...register("max_selected")}
                        />
                        <br />
                      </FormGroup>
                    </Col>
                    <Col xs={12} xl={6}>
                      <FormGroup>
                        <Label>
                          {"Минимальное количество опций для выбора"}
                        </Label>
                        <input
                          step={1}
                          type={"number"}
                          name={"min_selected"}
                          className={`form-control digits `}
                          {...register("min_selected")}
                        />

                        <br />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading
                      onClick={toggle}
                      color="primary"
                      loading={loading}
                    >
                      Добавить модификатор
                    </ButtonLoading>
                    <ButtonLoading
                      onClick={deleteGroupOptions}
                      color="danger"
                      loading={loading}
                    >
                      Удалить
                    </ButtonLoading>
                    <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
        <CommonModal
          title="Создание модификатора"
          isOpen={modal}
          toggler={toggle}
        >
          <Form
            className="theme-form"
            onSubmit={handleSubmitForm(addNewOption)}
          >
            <CardBody>
              {" "}
              <Row>
                <Col xs={12} xl={6}>
                  <FormGroup>
                    <Label>Название *</Label>
                    <input
                      className={`form-control ${
                        errorsForm.title ? "is-invalid" : ""
                      }`}
                      type="text"
                      name="title"
                      placeholder="Соус"
                      {...registerForm("title", { required: true })}
                    />
                    <span style={{ color: "red" }}>
                      {errorsForm.title &&
                        "Необходимо указать название модификатора"}
                    </span>
                  </FormGroup>
                </Col>
                <Col xs={12} xl={6}>
                  <FormGroup>
                    <Label>{"Цена модификатора *"}</Label>
                    <input
                      step={1}
                      type={"number"}
                      name={"price"}
                      className={`form-control digits ${
                        errorsForm.price ? "is-invalid" : ""
                      }`}
                      {...registerForm("price", { required: true })}
                    />
                    <span style={{ color: "red" }}>
                      {errorsForm.price &&
                        "Необходимо указать цену модификатора"}
                    </span>
                    <br />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div className="text-end">
                <ButtonLoading loading={loading}>Добавить</ButtonLoading>
              </div>
            </CardFooter>
          </Form>
        </CommonModal>
        <CommonModal
          title="Изменение модификатора"
          isOpen={modalChange}
          toggler={toggleChange}
        >
          <Form
            className="theme-form"
            onSubmit={handleSubmitFormChange(onChangeOption)}
          >
            <CardBody>
              {" "}
              <Row>
                <Col xs={12} xl={6}>
                  <FormGroup>
                    <Label>Название *</Label>
                    <input
                      className={`form-control ${
                        errorsFormChange.title ? "is-invalid" : ""
                      }`}
                      type="text"
                      name="title"
                      placeholder="Соус"
                      {...registerFormChange("title", { required: true })}
                    />
                    <span style={{ color: "red" }}>
                      {errorsFormChange.title &&
                        "Необходимо указать название модификатора"}
                    </span>
                  </FormGroup>
                </Col>
                <Col xs={12} xl={6}>
                  <FormGroup>
                    <Label>{"Цена модификатора *"}</Label>
                    <input
                      step={1}
                      type={"number"}
                      name={"price"}
                      className={`form-control digits ${
                        errorsFormChange.price ? "is-invalid" : ""
                      }`}
                      {...registerFormChange("price", { required: true })}
                    />
                    <span style={{ color: "red" }}>
                      {errorsFormChange.price &&
                        "Необходимо указать цену модификатора"}
                    </span>
                    <br />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div className="text-end">
                <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
              </div>
            </CardFooter>
          </Form>
        </CommonModal>
        {currentOptions.options &&
          currentOptions.options.map((item, index) => (
            <div key={item.id} style={{ cursor: "pointer" }}>
              <div className="category-container card">
                <div className={"card-body d-flex justify-content-between"}>
                  <div className="d-flex aligh-center gap-2">
                    <span>{item.title}</span>
                    <span style={{ opacity: 0.5 }}>+{item.price} ₽</span>
                  </div>
                  <div className="d-flex gap-2">
                    <div onClick={() => openChangeModal(item)}>
                      <i
                        style={{ color: "#7366ff" }}
                        className="fa fa-pencil"
                      ></i>
                    </div>
                    <div onClick={() => deleteOption(item.id)}>
                      <i
                        style={{ color: "#fc4438" }}
                        className="icofont icofont-ui-delete"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Container>
    </>
  );
};

export default UpdateOptionGroupPage;
