const tableColumns = [
  {
    name: "Название",
    selector: (row) => row.title,
    sortable: false,
    center: false,
  },
  {
    name: "Адрес",
    selector: (row) => row.address.text,
    sortable: false,
    center: false,
  },
  {
    name: "Статус",
    selector: (row) => (
      <span className={row.is_active ? "text-success" : "text-danger"}>
        {row.is_active ? "Активна" : "Закрыта"}
      </span>
    ),
    sortable: false,
    center: false,
  },
];

export default tableColumns;
