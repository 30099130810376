import React from "react";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import { YMInitializer } from "react-yandex-metrika";

const App = () => (
  <div className="App">
    <Provider store={store}>
      <CustomizerProvider>
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>
      </CustomizerProvider>
      <ToastContainer />
      {/*<YMInitializer*/}
      {/*  accounts={[97215969]}*/}
      {/*  options={{*/}
      {/*    clickmap: true,*/}
      {/*    trackLinks: true,*/}
      {/*    accurateTrackBounce: true,*/}
      {/*    webvisor: true,*/}
      {/*  }}*/}
      {/*/>*/}
    </Provider>
  </div>
);

export default App;
