import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import {
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import SweetAlert from "sweetalert2";
import {
  coreGetDetailRestaurantSettings,
  coreUpdateRestaurantSettings,
} from "../../../../api/core/restaurants";
import { useUserSelector } from "../../../../store/useSelectors";
import { TTariffOption } from "../../../../Constant/tariffs";
import Loader from "../../../../Layout/Loader";

import RestrictedCard from "../../../Common/Card/RestrictedCard";
import { TOrderPaymentMethod } from "../../../../Constant/orders";
import { DOCS_URLS } from "../../../../Constant/docs";
import ButtonLoading from "../../../Common/ButtonLoading/ButtonLoading";

const OnlinePaymentsSettings = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { selectedRestaurant } = useUserSelector();

  const isOnlinePaymentsEnabled = watch("enabled");

  useEffect(() => {
    coreGetDetailRestaurantSettings().then(
      (response) => response && setSettings(response.data)
    );
  }, []);

  useEffect(() => {
    if (!settings) return;

    const isEnabled = settings.payment_methods.includes(
      TOrderPaymentMethod.ONLINE
    );
    setValue("enabled", isEnabled);
  }, [settings]);
  const onSubmit = (data) => {
    const payload = {};

    const wasEnabled = settings.payment_methods.includes(
      TOrderPaymentMethod.ONLINE
    );

    if (wasEnabled !== data.enabled) {
      if (data.enabled) {
        payload["payment_methods"] = [
          ...settings["payment_methods"],
          TOrderPaymentMethod.ONLINE,
        ];
      } else {
        payload["payment_methods"] = settings["payment_methods"].filter(
          (item) => item !== TOrderPaymentMethod.ONLINE
        );
      }
    }

    Object.keys(data)
      .filter((key) => key !== "payment_methods")
      .forEach((key) => {
        if (settings[key] !== data[key]) {
          payload[key] = data[key];
        }
      });

    setLoading(true);
    coreUpdateRestaurantSettings(payload).then((response) => {
      setLoading(false);
      if (response) {
        setSettings(response.data);
        SweetAlert.fire("Настройки успешно обновлены!", "", "success");
      }
    });
  };

  if (!settings) return <Loader />;

  return (
    <>
      <Breadcrumbs
        mainTitle={"Онлайн-платежи"}
        title="Онлайн-платежи"
        parent="Настройки"
        docsLink={DOCS_URLS.SETTINGS_ONLINE_PAYMENTS}
      />
      <Container fluid={true}>
        <Row>
          <Col xs={12}>
            <RestrictedCard
              isRestricted={
                !selectedRestaurant?.tariff.options[
                  TTariffOption.ONLINE_PAYMENTS
                ]
              }
            >
              <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row className="justify-content-between gap-2">
                    <Col xs={12} className={"mb-5"}>
                      <Media>
                        <Label className="col-form-label m-r-10">
                          <h6>Включить онлайн-платежи</h6>
                        </Label>
                        <Media body className="text-end switch-lg icon-state">
                          <Label className="switch">
                            <input
                              type="checkbox"
                              name="enabled"
                              {...register("enabled")}
                            />
                            <span className="switch-state" />
                          </Label>
                        </Media>
                      </Media>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={12}>
                          <FormGroup>
                            <Label>Платёжный токен</Label>
                            <input
                              className={`form-control ${
                                errors.provider_token ? "is-invalid" : ""
                              }`}
                              type="text"
                              name="payments_provider_token"
                              placeholder={"381764333:TEST:83312"}
                              disabled={!isOnlinePaymentsEnabled}
                              {...register("payments_provider_token")}
                            />

                            <div className={"mt-2"}>
                              <span className={"text-muted"}>
                                Мы скрываем платёжный токен для безопасности,
                                если хотите изменить токен — вставьте новый
                              </span>
                            </div>
                            <small className="form-text text-muted">
                              <a
                                href={DOCS_URLS.SETTINGS_ONLINE_PAYMENTS}
                                target={"_blank"}
                              >
                                Где взять платёжный токен?
                              </a>
                            </small>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className="text-end">
                    <ButtonLoading loading={loading}>Сохранить</ButtonLoading>
                  </div>
                </CardFooter>
              </Form>
            </RestrictedCard>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OnlinePaymentsSettings;
