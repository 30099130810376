import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { coreGetAddressSuggestions } from "../../../api/core/addresses";

const AddressSelect = ({ isDisabled, onChange, value }) => {
  const [selectLoader, setSelectLoader] = useState("Начните вводить адрес");
  const [inputValue, setInputValue] = useState(null);
  const [_timeout, _setTimeout] = useState(null);

  const [selectValue, setSelectValue] = useState(value || null);

  useEffect(() => {
    if (!inputValue || inputValue.length === 0) {
      setSelectLoader("Начните вводить адрес");
    }
  }, [inputValue]);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const loadOptions = (inputValue, callback) => {
    const timeout = setTimeout(() => {
      coreGetAddressSuggestions(inputValue).then((response) => {
        if (response.status === 200) {
          if (response.data.length === 0 && inputValue.length > 0) {
            setSelectLoader("Указанный адрес не найден");
          }
          callback(response.data);
        }
      });
    }, 1000);
    if (_timeout) {
      clearTimeout(_timeout);
    }

    _setTimeout(timeout);
  };

  return (
    <AsyncSelect
      isDisabled={isDisabled}
      value={selectValue}
      placeholder={"Введите адрес"}
      cacheOptions
      loadOptions={loadOptions}
      isClearable={false}
      noOptionsMessage={() => selectLoader}
      loadingMessage={() => "Загрузка..."}
      onChange={(value) => {
        setSelectValue(value);
        onChange(value);
      }}
      onInputChange={(inputValue) => {
        setInputValue(inputValue);
      }}
      getOptionValue={(e) => e.text}
      getOptionLabel={(e) => e.text}
    />
  );
};

export default AddressSelect;
