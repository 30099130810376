import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Media,
  Row,
} from "reactstrap";
import { Btn, H5, Image, ToolTip } from "../../../AbstractElements";
import { FaPlay, FaSpinner, FaStop } from "react-icons/fa";
import { useUserActions } from "../../../store/useActions";
import { useNavigate } from "react-router";
import { coreBotStart, coreBotStop } from "../../../api/core/bots";
import telegramIconSrc from "../../../assets/images/telegram-icon.png";
import { restaurantTelegramBotStateMap } from "../../../Constant/restaurants";
import UserAvatar from "../../Common/UserAvatar/UserAvatar";
import { useUserSelector } from "../../../store/useSelectors";
import { MoneyFormat } from "../../../Services/utils";
import { TTariffPeriod, TTariffOption } from "../../../Constant/tariffs";

const RestaurantCard = ({ restaurant, fetchRestaurants, ...props }) => {
  const { setSelectedRestaurant } = useUserActions();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [creatorAvatarTooltip, setCreatorAvatarTooltip] = useState(false);
  const { userData } = useUserSelector();

  const selectRestaurant = () => {
    setSelectedRestaurant(restaurant);
    navigate(
      `${process.env.PUBLIC_URL}/restaurants/${restaurant.id}/dashboard`
    );
  };

  const onClickStart = () => {
    setLoading(true);
    coreBotStart(restaurant.id)
      .then((response) => {
        if (response.status === 200) {
          fetchRestaurants();
        }
      })
      .finally(() => setLoading(false));
  };

  const onClickStop = () => {
    setLoading(true);
    coreBotStop(restaurant.id)
      .then((response) => {
        if (response.status === 200) {
          fetchRestaurants();
        }
      })
      .finally(() => setLoading(false));
  };

  const stateToButtonAction = {
    CREATED: (
      <Button color="outline-primary" className="w-100" onClick={onClickStart}>
        <FaPlay className={"m-r-5 inherit"} /> Запустить
      </Button>
    ),
    STARTED: (
      <Button color="outline-secondary" className="w-100" onClick={onClickStop}>
        <FaStop className={"m-r-5 inherit"} /> Остановить
      </Button>
    ),
    STARTING: (
      <Button color="outline-secondary" className="w-100">
        <FaStop className={"m-r-5 inherit"} /> Остановить
      </Button>
    ),
    STOPPED: (
      <Button color="outline-primary" className="w-100" onClick={onClickStart}>
        <FaPlay className={"m-r-5 inherit"} /> Запустить
      </Button>
    ),
    FAILED: (
      <Button color="outline-primary" className="w-100" onClick={onClickStart}>
        <FaPlay className={"m-r-5 inherit"} /> Запустить
      </Button>
    ),
    LOADING: (
      <Button color="outline-info" className="w-100">
        <FaSpinner className={"m-r-5 inherit spinner-animation"} /> Загрузка
      </Button>
    ),
  };

  return (
    <Card className="social-profile h-100 browser-widget">
      <CardBody className={"pb-0"} onClick={selectRestaurant}>
        <div className="social-img-wrap">
          <div className="social-img">
            <Image attrImage={{ src: telegramIconSrc, alt: "profile" }} />
          </div>
          {/*TODO: Можно ставить галочку у заведений, у которых не фри-тариф*/}
          {/*<div className="edit-icon">*/}
          {/*  <SvgIcon iconId="profile-check" />*/}
          {/*</div>*/}
        </div>
        <div className="social-details">
          <H5 attrH5={{ className: "mb-1" }}>{restaurant.title}</H5>
          <span className="f-light">@{restaurant.telegram_bot.username}</span>
          {userData.id !== restaurant.creator.id ? (
            <>
              <div className="d-flex justify-content-center">
                <Media
                  id={`id${restaurant.id}`}
                  className="d-flex gap-2 mt-3  align-items-center justify-content-center"
                >
                  <UserAvatar
                    src={restaurant.creator.photo}
                    id={restaurant.creator.id}
                    size={30}
                  />
                  <Media>
                    <div>{restaurant.creator.first_name}</div>
                  </Media>
                </Media>
              </div>
              <ToolTip
                attrToolTip={{
                  placement: "bottom",
                  isOpen: creatorAvatarTooltip,
                  target: `id${restaurant.id}`,
                  toggle: () => setCreatorAvatarTooltip(!creatorAvatarTooltip),
                }}
              >
                Заведение принадлежит указанному пользователю, и вам выдали к
                нему доступ
              </ToolTip>
            </>
          ) : (
            <div className="d-flex justify-content-center mt-3">
              {restaurant.tariff_subscription !== null ? (
                <>
                  {MoneyFormat.format(restaurant.tariff_cost)} /{" "}
                  {TTariffPeriod.getShortTitle(
                    restaurant.tariff_subscription.tariff_period
                  )}
                </>
              ) : (
                "Бесплатно"
              )}
            </div>
          )}

          {/*<UL attrUL={{className: 'social-follow'}}>*/}
          {/*    <LI>*/}
          {/*        <H5 attrH5={{className: 'mb-0'}}>1300</H5>*/}
          {/*        <span className='f-light'>Posts</span>*/}
          {/*    </LI>*/}
          {/*    <LI>*/}
          {/*        <H5 attrH5={{className: 'mb-0'}}>1300</H5>*/}
          {/*        <span className='f-light'>Posts</span>*/}
          {/*    </LI>*/}
          {/*    <LI>*/}
          {/*        <H5 attrH5={{className: 'mb-0'}}>1333</H5>*/}
          {/*        <span className='f-light'>Posts</span>*/}
          {/*    </LI>*/}
          {/*</UL>*/}
        </div>
      </CardBody>
      <CardFooter>
        <Row>
          {loading ? (
            <Col xs={12}>{stateToButtonAction["LOADING"]}</Col>
          ) : (
            <Col xs={12}>
              {stateToButtonAction[restaurant.telegram_bot.state]}
            </Col>
          )}

          <Col xs={12} className={"mt-2"}>
            {restaurantTelegramBotStateMap[restaurant.telegram_bot.state]}
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default RestaurantCard;
