import React from "react";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import {
  convertCoreApiDateToLocalString,
  formatPhoneNumber,
} from "../../../../Services/utils";
import {
  TOrderState,
  orderDeliveryTypeMap,
  orderPaymentMethodMap,
  orderStateToTextMap,
  TOrderPaymentMethod,
} from "../../../../Constant/orders";
import { Link } from "react-router-dom";
import { useUserSelector } from "../../../../store/useSelectors";
import UserAvatar from "../../../Common/UserAvatar/UserAvatar";
import OrderCancelButton from "./OrderCancelButton";
import OrderUpdateButton from "./OrderUpdateButton";

const OrderInfoCard = ({ order, setOrder }) => {
  const { selectedRestaurant } = useUserSelector();

  return (
    <Card>
      <HeaderCard title={`Информация о заказе`} />
      <CardBody>
        <Row>
          <Col xs={12}>
            <h6>{`Статус`}</h6>
            <p>{orderStateToTextMap[order.state]}</p>
          </Col>
          <hr className={"mt-4 mb-4"} />
          <Col xs={12}>
            <h6>{`Сумма заказа`}</h6>
            <p>
              {order.cost} ₽{" "}
              {order.pretotal_cost !== order.cost ? (
                <s className={"m-l-10"}>{order.pretotal_cost} ₽</s>
              ) : null}
            </p>
          </Col>
          <hr className={"mt-4 mb-4"} />
          <Col xs={12}>
            <h6>{`Вид доставки`}</h6>
            <p
              className={
                order.delivery_type === "TABLE"
                  ? "txt-primary"
                  : order.delivery_type === "DELIVERY"
                  ? "txt-info"
                  : null
              }
            >
              {orderDeliveryTypeMap[order.delivery_type]}{" "}
              {order.delivery_type === "TABLE"
                ? `#${order.table_number}`
                : null}
            </p>
          </Col>

          <hr className={"mt-4 mb-4"} />
          <Col xs={12}>
            <h6>{`Способ оплаты`}</h6>
            <p>
              {orderPaymentMethodMap[order.payment_method]}
              {order.payment_method === TOrderPaymentMethod.ONLINE ? (
                order.payed_at ? (
                  <span className={"text-success"}> (Оплачен)</span>
                ) : (
                  <span className={"text-danger"}> (Не оплачен)</span>
                )
              ) : null}
            </p>
          </Col>

          {order.by_time ? (
            <>
              <hr className={"mt-4 mb-4"} />
              <Col xs={12}>
                <h6>{`Заказ ко времени`}</h6>
                <p>
                  {convertCoreApiDateToLocalString(order.by_time, {
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
              </Col>
            </>
          ) : null}

          <hr className={"mt-4 mb-4"} />
          <Col xs={12}>
            <h6>{`Номер телефона`}</h6>
            <p>{formatPhoneNumber(order.phone_number)}</p>
          </Col>

          <hr className={"mt-4 mb-4"} />
          <Col xs={12}>
            <Link
              to={`${process.env.PUBLIC_URL}/restaurants/${selectedRestaurant.id}/users/${order.restaurant_user.id}`}
            >
              <Media className="d-flex gap-3">
                <UserAvatar
                  src={order.restaurant_user.user.photo}
                  id={order.restaurant_user.user.id}
                  size={40}
                />
                <Media body className="align-self-center">
                  <div>{order.restaurant_user.user.first_name}</div>
                </Media>
              </Media>
            </Link>
          </Col>

          {order.comment ? (
            <>
              <hr className={"mt-4 mb-4"} />
              <Col xs={12}>
                <h6>Комментарий</h6>
                <p>{order.comment}</p>
              </Col>{" "}
            </>
          ) : null}

          {[
            TOrderState.CREATED,
            TOrderState.COOKING,
            TOrderState.DELIVERING,
          ].includes(order.state) && (
            <>
              <hr className={"mt-4 mb-4"} />{" "}
              <Col xs={12} className="d-flex flex-column gap-3">
                <OrderUpdateButton order={order} setOrder={setOrder} />
                <OrderCancelButton order={order} />
              </Col>
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default OrderInfoCard;
