import React, { Fragment, useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import CustomizerContext from "../_helper/Customizer";
import Loader from "./Loader";
import { useUserSelector } from "../store/useSelectors";
import { useUserActions } from "../store/useActions";
import { coreClient, processResponseError } from "../api/core";
import { coreGetMe } from "../api/core/users";
import { coreGetDetailRestaurant } from "../api/core/restaurants";
import { useNavigate, useParams } from "react-router";

function ScrollToTop() {
  // При смене страницы скролл не меняется, эта штука фиксит это
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppLayout = ({ children, classNames, ...rest }) => {
  const { sidebarIconType } = useContext(CustomizerContext);
  const location = useLocation();

  const navigate = useNavigate();

  const { restaurantId } = useParams();
  const { userData, token, selectedRestaurant } = useUserSelector();
  const { setUserData, setSelectedRestaurant } = useUserActions();

  const navigateToLogin = () => {
    navigate(`${process.env.PUBLIC_URL}/login?next=${location.pathname}`);
  };

  useEffect(() => {
    if (!restaurantId && selectedRestaurant) {
      setSelectedRestaurant(null);
    }

    if (userData && restaurantId && !selectedRestaurant) {
      fetchRestaurant();
    }
  }, [restaurantId]);

  useEffect(() => {
    setupMode();

    if (!token) {
      navigateToLogin();
      return;
    }

    if (userData === null) {
      coreClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      coreGetMe()
        .then((response) => {
          if (response) {
            setUserData(response.data);
            fetchRestaurant();
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            localStorage.removeItem("token");
            navigateToLogin();
          } else {
            processResponseError(error);
          }
        });
    }
  }, []);

  const fetchRestaurant = () => {
    if (
      restaurantId &&
      restaurantId !== (selectedRestaurant ? selectedRestaurant.id : null)
    ) {
      coreGetDetailRestaurant(restaurantId).then((response) => {
        if (response) {
          setSelectedRestaurant(response.data);
        }
      });
    }
  };

  const setupMode = () => {
    const isDarkMode = localStorage.getItem("isDarkMode");
    document.body.className =
      isDarkMode === "true" ? "dark-only" : "light-only";
  };

  if (
    userData === null ||
    (restaurantId && selectedRestaurant === null) ||
    // Выше сбрасываем состояние при этом условии и нужен ререндер
    (!restaurantId && selectedRestaurant)
  ) {
    return <Loader />;
  }

  return (
    <Fragment>
      <ScrollToTop />
      <Taptop />
      <div
        className={`page-wrapper compact-wrapper modern-type`}
        sidebar-layout={sidebarIconType}
        id="pageWrapper"
      >
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            {/*<TransitionGroup {...rest}>*/}
            {/* Из-за нее рендерится дважды компонент */}
            {/*<CSSTransition location={location} key={location.key} timeout={100} classNames={animationTheme}>*/}
            <div>
              <div>
                <Outlet />
              </div>
            </div>
            {/*</CSSTransition>*/}
            {/*</TransitionGroup>*/}
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};
export default AppLayout;
